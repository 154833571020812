<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
    </fieldset>
    <el-row style="margin-top: 10px;padding-left: 10px">
      <el-table
          :data="table.earningList"
          :row-style="{height:30+'px'}"
          :cell-style="{padding:5+'px'}"
          :stripe="true"
          border
          height="650"
          max-height="650"
          :default-sort="{prop: 'id', order: 'ascending'}"
          style="width: 99%">
        <el-table-column prop="date" label="日期" align="center"></el-table-column>
        <el-table-column prop="cusName" label="客户名称" align="center"></el-table-column>
        <el-table-column prop="orderDressName" label="礼服师" align="center"></el-table-column>
        <el-table-column prop="shopName" label="店铺名称" align="center"></el-table-column>
        <el-table-column prop="saleReception" label="售前接待数" align="center"></el-table-column>
        <el-table-column prop="onceTheOrder" label="一次订单数" align="center"></el-table-column>
        <el-table-column prop="onceTheOrderX" label="小定数" align="center"></el-table-column>
        <el-table-column prop="twiceTheOrder" label="二次订单数" align="center"></el-table-column>
        <el-table-column prop="onceTheEarning" label="一销业绩" align="center"></el-table-column>
        <el-table-column prop="earning" label="总业绩" align="center"></el-table-column>
        <el-table-column align="center" width="150" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="primary" size="mini" @click="editEarningOpen(scope.row)">
                修改
              </el-button>
              <el-button type="danger" size="mini" @click="delEarning(scope.row)">
                删除
              </el-button>
            </el-button-group>
          </template>
        </el-table-column>

      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
    <EarningDataEdit :earning-edit-state="earningEditState" :earning-edit-data="earningEditData" v-if="earningEditState"/>
  </div>
</template>

<script>
import EarningDataEdit from "@/pages/statistic/shop-reports/earning-data-edit.vue";

export default {
  name: "earning-data",
  data() {
    return {
      tenantCrop: localStorage.getItem("tenantCrop"),
      operationType: "",
      moneyType: "",
      table: {
        earningList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      earningEditState:false,
      earningEditData:{},
    }
  },
  components: {
    EarningDataEdit,
  },
  created() {
    this.queryEarningList();
  },
  methods: {
    search() {
      this.table.page = 1;
      this.queryEarningList();
    },
    //查询客户列表
    queryEarningList: function () {
      this.$axios({
        method: "GET",
        url: "/separateOrder/querySeparateOrderList",
        params: {
          page: this.table.page,
          limit: this.table.limit,
          tenantCrop: this.tenantCrop,
        }
      }).then(response => {
        console.log(response)
        this.table.total = response.data.data.total;
        this.table.earningList = response.data.data.list;
      })
    },
    editEarningOpen(item){
      this.earningEditData=item
      this.earningEditState=true;
      console.log(this.earningEditData)
      console.log(this.earningEditState)
    },
    delEarning(item){
      this.$alert('确定删除吗？', "修改", {
        dangerouslyUseHTMLString: true,
        type: "warning",
        showCancelButton: true
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/separateOrder/deleteSeparateOrder/",
          data: {
            id:item.id
          }
        }).then(response => {
          let flag = response.data.code === 200
          this.$message({
            showClose: true,
            message: flag ? '删除成功' : response.data.msg,
            type: flag ? 'success' : 'error',
            duration: 1000,
          });
          if (flag) {
            this.close();
          }
        })
      })
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryEarningList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryEarningList()
    },
  },
}
</script>

<style scoped>

</style>