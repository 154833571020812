<template>
  <div>
    <el-dialog
        title="业绩编辑"
        width="55%"
        :visible.sync="state"
        :modal-append-to-body="false"
        @close="close"
        center>
      <div>
        <el-form label-width="110px" :model="form" :rules="rules" ref="form">
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item label="用户名" prop="cusName">
                <el-input v-model="form.cusName" placeholder="请输入用户名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="礼服师" prop="orderDressName">
                <el-input v-model="form.orderDressName" placeholder="请输入礼服师"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="店铺" prop="shopName">
                <el-input v-model="form.shopName" placeholder="请输入店铺名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item label="售前接待数量" prop="saleReception">
                <el-input v-model.number="form.saleReception" placeholder="请输入接待数量"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="一次订单数" prop="onceTheOrder">
                <el-input v-model.number="form.onceTheOrder" placeholder="请输入一次订单数"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="小定数" prop="onceTheOrderX">
                <el-input v-model.number="form.onceTheOrderX" placeholder="请输入小定数"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item label="二次订单数" prop="twiceTheOrder">
                <el-input v-model.number="form.twiceTheOrder" placeholder="请输入二次订单数"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="一销业绩" prop="onceTheEarning">
                <el-input v-model.number="form.onceTheEarning" placeholder="请输入一销业绩"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="总业绩" prop="earning">
                <el-input v-model.number="form.earning" placeholder="请输入总业绩"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="editSeparateOrder">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "earning-add",
  created() {
    this.pageInit()
  },
  props: {
    earningEditState: {
      type: Boolean,
      default: false,
      required: true,
    },
    earningEditData: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      state: this.earningEditState,
      form: {
        ...this.earningEditData
      },
      rules: {
        cusName: [{required: true, message: "请输入用户名", trigger: "change"}],
        orderDressName: [{required: true, message: "请输入礼服师", trigger: "blur"}],
        shopName: [{required: true, message: "请输入店铺名称", trigger: "blur"}],
        saleReception: [{required: true, message: "请输入接待数量", trigger: "blur"}],
        onceTheOrder: [{required: true, message: "请输入一次订单数", trigger: "blur"}],
        onceTheOrderX: [{required: true, message: "请输入小定数", trigger: "blur"}],
        twiceTheOrder: [{required: true, message: "请输入二次订单数", trigger: "blur"}],
        onceTheEarning: [{required: true, message: "请输入一销业绩", trigger: "blur"}],
        earning: [{required: true, message: "请输入总业绩", trigger: "blur"}],
      },
    }
  },
  methods: {
    pageInit() {
      console.log(this.form)
    },
    close() {
      this.$emit("close");
    },
    editSeparateOrder() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$alert('确定修改吗？', "修改", {
            dangerouslyUseHTMLString: true,
            type: "warning",
            showCancelButton: true
          }).then(() => {
            this.$axios({
              method: "POST",
              url: "/separateOrder/editSeparateOrder/",
              data: {
                ...this.form
              }
            }).then(response => {
              let flag = response.data.code === 200
              this.$message({
                showClose: true,
                message: flag ? '编辑成功' : response.data.msg,
                type: flag ? 'success' : 'error',
                duration: 1000,
              });
              if (flag) {
                this.close();
              }
            })
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>